import { Link, navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Grid } from "@material-ui/core";
import NewsletterRow from "../newsletter/NewsletterRow";
import PageTitle from "../core/PageTitle";
import Layout from "../core/Layout";
import ArticleCardV2 from "../../tailwindComponents/ArticleCardV2";
require("prismjs/themes/prism-tomorrow.css");

interface ArticlesProps {
  pageContext: {
    articles: Array<{
      frontmatter: {
        title: string;
        description: string;
        slug: string;
        date: string;
        tags?: string[];
        heroImageUrl: any;
      };
    }>;
  };
}

export default function Articles(props: ArticlesProps) {
  const classes = useStyles();
  return (
    <>
      <Layout
        maxWidth="lg"
        pageTitle="Jetpack Compose Articles - Expert Tutorials and Guides"
        pageDescription="Discover expert tutorials, articles and insights about Jetpack Compose. Learn how to build beautiful Android UIs with the latest in modern UI development."
        pageSlug="/articles"
        pageImageUrl="/articles_poster.png"
        seoImageUrl="/articles_poster.png"
        articleTags={[
          "Jetpack Compose",
          "Android Development",
          "Kotlin",
          "UI Development",
          "Mobile Development",
        ]}
        pageType="website"
        structuredData={{
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          headline: "Jetpack Compose Articles - Expert Tutorials and Guides",
          description:
            "Discover expert tutorials, articles and insights about Jetpack Compose. Learn how to build beautiful Android UIs with the latest in modern UI development.",
          url: "https://www.jetpackcompose.app/articles",
          publisher: {
            "@type": "Organization",
            name: "JetpackCompose.app",
            logo: {
              "@type": "ImageObject",
              url: "https://www.jetpackcompose.app/logo.png",
            },
          },
        }}
      >
        <PageTitle header="Articles" subheader="" />
        <Grid
          container
          lg={12}
          xs={12}
          className={classes.articleGridContainer}
        >
          {props.pageContext.articles.map((article) => {
            return (
              <Grid item lg={4} xs={12} md={6} key={article.frontmatter.slug}>
                <Grid
                  container
                  justify="center"
                  className={classes.cardContainer}
                >
                  <Link
                    to={article.frontmatter.slug}
                    partiallyActive={true}
                    onClick={() => {
                      // gtag isn't available in localhost so using this workaround
                      if (
                        window &&
                        !window.location.href.includes("localhost")
                      ) {
                        window.gtag(`event`, `click`, {
                          event_category: `engagement`,
                          event_label: article.frontmatter.slug,
                        });
                      }
                    }}
                  >
                    <ArticleCardV2
                      title={article.frontmatter.title}
                      description={article.frontmatter.description}
                      dynamicImage={article.frontmatter.heroImageUrl}
                      date={article.frontmatter.date}
                      tags={article.frontmatter.tags}
                    />
                  </Link>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <div className={classes.newsletter}>
          <NewsletterRow />
        </div>
      </Layout>
    </>
  );
}

const useStyles = makeStyles({
  cardContainer: {
    padding: 16,
  },
  articleGridContainer: {
    marginBottom: "96px",
  },
  newsletter: {
    marginBottom: 64,
    margin: "auto",
    maxWidth: 960,
    textAlign: "center",
  },
});
